/* Updated CSS for Contact Component */

/* Ensure the section starts below the navbar */
.contact-section {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  /* Assuming 80px navbar height */
  padding-top: 80px;
  /* To prevent overlapping with navbar */
  margin: 0 auto;
  overflow: hidden;
  background-color: rgba(3, 71, 99, 0.842);
}


.contact-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  background-color: #063970;
}

.contact-form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  width: 100%;
  max-width: 600px;
  background-color: var(--whiteColor);
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.contact-form h1 {
  color: var(--PrimaryColor);
  text-align: center;
  margin-bottom: 1.5rem;
}

.contact-form p {
  color: var(--textColor);
  text-align: center;
}

/* Input and Textarea styling */
.contact-form input,
.contact-form textarea {
  width: 100%;
  margin: 0.75rem 0;
  padding: 0.75rem;
  border-radius: 5px;
  border: 1px solid var(--greyText);
  font-size: 1rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease;
}

.contact-form textarea {
  resize: vertical;
  min-height: 100px;
}

/* Button styling */
.contact-form button {
  width: 100%;
  background-color: var(--SecondaryColor);
  color: var(--whiteColor);
  padding: 0.75rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s;
}

.contact-form button:hover {
  background-color: var(--HoverColor);
  transform: scale(1.05);
}

/* Error and Success Messages */
.success-message {
  color: var(--SuccessColor);
  text-align: center;
}

.error-message {
  color: var(--ErrorColor);
  text-align: center;
}

/* Service Details Section */
.service-details {
  margin-top: 2rem;
  color: var(--SecondaryColor);
  padding: 1rem;
}

.service-details div {
  margin-bottom: 1.5rem;
}

.service-details h3 {
  margin-bottom: 0.75rem;
  font-size: 1.3rem;
  color: var(--PrimaryColor);
}

.service-details ul {
  list-style-type: disc;
  margin-left: 1.5rem;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .contact-form {
    padding: 1.5rem;
  }

  .contact-form h1 {
    font-size: 1.5rem;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 0.9rem;
  }

  .service-details h3 {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .contact-section {
    flex-direction: column;
    height: auto;
    /* Allow content to flow naturally on smaller screens */
  }

  .contact-form {
    margin: 0;
    padding: 1rem;
  }

  .contact-form h1 {
    font-size: 1.25rem;
  }

  .contact-form input,
  .contact-form textarea {
    padding: 0.5rem;
  }

  .service-details h3 {
    font-size: 1rem;
  }

  .service-details p {
    font-size: 0.9rem;
  }
}