/* About.css */

:root {
  --PrimaryColor: #269ccc;      /* Updated Deep Sky Blue */
  --SecondaryColor: #ff5722;    /* Vibrant Orange */
  --AccentColor: #ffca28;       /* Golden Yellow */
  --HoverColor: #9ed2c5;        /* Updated Lighter Greenish Blue for Hover */
  --LightAccent: #ffecb3;       /* Soft Yellow */
  
  --whiteColor: hsl(0, 0%, 100%);       /* Pure White */
  --blackColor: #7b7b7b;                /* Updated Dark Grey */
  --textColor: hsl(240, 16%, 17%);      /* Updated Dark Navy Blue for Text */
  
  --greyBg: hsl(0, 0%, 96%);            /* Updated Light Grey for Backgrounds */
  --whiteColorDeam: hsl(0, 0%, 93%);    /* Updated Slightly Off-White */
  --greyText: rgb(190, 190, 190);       /* Updated Light Grey for Subtext */
  --inputColor: hsl(330, 12%, 97%);     /* Very Light Blue for Input Backgrounds */
  
  --SuccessColor: #4caf50;              /* Bright Green for Success */
  --WarningColor: #ff9800;              /* Bright Orange for Warnings */
  --ErrorColor: #f44336;                /* Bright Red for Errors */
  --InfoColor: #03a9f4;                 /* Light Blue for Info */
}

.about-us-section {
  margin: 0 auto;
  padding: 20px 20px; /* Adjust padding for better mobile spacing */
  background-color: hsl(0, 0%, 100%);
}

 .about-us-section h2 {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  color: #027c68;
  margin-top: 50px;
  font-weight: 500;
}


.about-us-section p {
  text-align: center;
  font-size: 1.1rem;
  line-height: 1.8;
  color: #4a4a4a;
  margin-bottom: 10px;
}

.about-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* More flexible grid layout */
  gap: 10px;
  align-items: center;
  justify-items: center;
}

.about-point {
  background-color: var(--SuccessColor);
  border-radius: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; /* Ensures space between elements */
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  min-height: 400px; /* Set a minimum height for uniformity */
  max-height: 400px; /* Set a maximum height to prevent overflow */
  overflow: hidden;  /* Prevent content from overflowing */
}

.about-content {
  flex-grow: 1; /* Allows the content area to grow and fill space */
  display: flex;
  flex-direction: column; /* Stacks content vertically */
  justify-content: space-between; /* Evenly spaces content within the section */
}

.about-point:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.about-icon {
  font-size: 5rem; /* Increased icon size for better visibility */
  color: #027c68;
  margin-top: 30px; /* Add space below icon */
}

.about-content h3 {
  font-size: 1.5rem; /* Adjusted font size for better readability */
  color: #003333;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.about-content p {
  font-size: 0.5rem; /* Increased font size for better readability */
  color: #080808;
  line-height: 1.5;
  font-family: 'Open Sans';
}

@media (min-width: 992px) {
  .about-content h3 {
      font-size: 1.8rem; /* Larger subheading on larger screens */
  }

  .about-content p {
      font-size: 1.2rem;
  }

  .about-us-section h1 {
      font-size: 2.8rem; /* Larger heading on larger screens */
  }
}

/* For medium devices (tablets, 768px to 991px) */
@media (min-width: 768px) and (max-width: 991px) {
  .about-content h3 {
      font-size: 1.5rem;
  }

  .about-content p {
      font-size: 1.1rem;
  }

  .about-us-section h1 {
      font-size: 2.3rem;
  }
}

/* For small devices (phones, less than 768px) */
@media (max-width: 767px) {
  .about-grid {
      grid-template-columns: 1fr; /* Single column on small devices */
  }

  .about-us-section {
      padding: 20px 15px;
  }

  .about-content h3 {
      font-size: 1.3rem;
  }

  .about-content p {
      font-size: 1rem;
  }

  .about-icon {
      font-size: 4rem; /* Slightly larger icon for better visibility on small screens */
      margin-bottom: 15px;
  }

  .about-us-section h1 {
      font-size: 2rem;
  }
}
