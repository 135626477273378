.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  background: white;
  border-radius: 10px;
  padding: 10px; /* Add padding for content */
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center align children */
}

/* Modal image styles */
.modal-image {
  max-height: 70vh; /* Limit height of the image to 70% of viewport height */
  max-width: 100%; /* Ensure the image fits within the container */
  border-radius: 10px;
  object-fit: contain; /* Keep aspect ratio of the image */
}

/* Responsive image settings for mobile */
@media (max-width: 768px) {
  .modal-image {
    max-height: 80vh; /* Limit height on mobile */
    width: 100%; /* Full width on mobile */
  }
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  background: #FF5F6D;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
  z-index: 1001; /* Ensure close button is above modal content */
}

.close-button:hover {
  background: #FFC371;
}

/* Navigation buttons */
.nav-button {
  background-color: #FF5F6D; /* Example color */
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.nav-button:disabled {
  background-color: #ccc; /* Disabled state */
  cursor: not-allowed;
}

.nav-button:hover:not(:disabled) {
  background-color: #FFC371; /* Hover color */
}

/* Flexbox for navigation controls */
.modal-controls {
  display: flex;
  justify-content: space-between; /* Space between buttons */
  margin-top: 10px; /* Add spacing above controls */
  padding: 10px; /* Ensure controls are within modal padding */
  width: 100%; /* Full width for controls */
}
