
:root {
    --PrimaryColor: #269ccc;      /* Updated Deep Sky Blue */
    --SecondaryColor: #ff5722;    /* Vibrant Orange */
    --AccentColor: #ffca28;       /* Golden Yellow */
    --HoverColor: #9ed2c5;        /* Updated Lighter Greenish Blue for Hover */
    --LightAccent: #ffecb3;       /* Soft Yellow */
    
    --whiteColor: hsl(0, 0%, 100%);       /* Pure White */
    --blackColor: #7b7b7b;                /* Updated Dark Grey */
    --textColor: hsl(240, 16%, 17%);      /* Updated Dark Navy Blue for Text */
    
    --greyBg: hsl(0, 0%, 96%);            /* Updated Light Grey for Backgrounds */
    --whiteColorDeam: hsl(0, 0%, 93%);    /* Updated Slightly Off-White */
    --greyText: rgb(190, 190, 190);       /* Updated Light Grey for Subtext */
    --inputColor: hsl(330, 12%, 97%);     /* Very Light Blue for Input Backgrounds */
    
    --SuccessColor: #4caf50;              /* Bright Green for Success */
    --WarningColor: #ff9800;              /* Bright Orange for Warnings */
    --ErrorColor: #f44336;                /* Bright Red for Errors */
    --InfoColor: #03a9f4;                 /* Light Blue for Info */
  }

.navBarSection{
    .header{
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: white;
        width: 100%;
        height: 10%;
        padding: 0.5rem;
        z-index: 1000;
        

        .logoDiv {
            .logo {
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
          
              .logoImage {
                max-width: 200px;  /* Adjust this based on your desired size */
                height: auto;      /* Maintain aspect ratio */
              }
            }
        }

        @media screen and (max-width: 768px) {
            .navBar{
                background: #f6feff;
                position: absolute;
                height: max-content;
                padding: 1rem;
                width: 100%;
                top: 0;
                left :-500%;
                box-shadow: 0 2px 4px rgba(240, 222, 222, 0.904);
                transition: .5s ease;
                z-index : 1000;

                .navLists{
                    flex-direction: column;
                    justify-content : center;
                    width: 100%;
                    margin : auto;

                    .navItem{
                        padding: 1rem 0;

                        .navLink{
                            color: var(--whiteColor);
                            font-size: .9rem;
                            font-weight: 600;
                            &:hover{
                                color: var(--LightAccent);
                            }  
                        }
                    }


                    .headerBtns{
                        flex-direction: column;
                        .btn{
                            margin-top: 1rem;

                            a{
                                font-weight: 600;
                                &:hover{
                                    color: var(--whiteColor);

                                }
                            }
                        }
                    }
                }

                .closeNavBar{
                    position: absolute;
                    top: 1rem;
                    right: 1.5rem;
                    color: var(--PrimaryColor);
                    &:hover .icon{
                        color: var(--LightAccent);                    }
                }
            }

            .activeNavbar{
                left: 0;
            }
        }

        .toggleNavbar{
            .icon{
                font-size: 25px;
                color: var(--blackColor);
                &:hover{
                    color: var(--PrimaryColor)
                }
            }

        }
    }


    .activeHeader{
        background:  white;
        box-shadow: 0 2px 8px 2px rgba(178,178,178,0.45);
    }
}

@media screen and (min-width: 760px) {

    .navBarSection{

        .toggleNavbar, .closeNavBar{
            display: none;
        }

        .header{
            padding: 1.5rem 2rem;

            .navBar{
                .navLists{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: row;


                    .navItem{
                        .navLink{
                            color: var(--whiteColor);
                            padding: 0.45rem;
                            font-size: .9rem;
                            font-weight: 400;
                            &:hover{
                                color:var(--PrimaryColor)
                            }
                        }

                    }


                    .btn{
                        margin-left: 1rem;

                        a{
                            color: var(--whiteColor);
                            font-weight: 500;
                        }
                    }
                    
                }
            }
        }
    }
}


@media screen and (min-width: 960px) {

    .header{
        .navBar{
            .navLists{
                .navItem{
                    padding: 0 .7rem;
                }
            }
        }
    }
    
}

@media screen and (min-width: 1024px) {

    .header{
       padding: 1rem 0.5rem !important; 
    }
    
}