.footer {
    background-color: #3a3e4294; /* Dark background */
    color: #fff; /* White text */
    padding: 0.5rem 0; /* Vertical padding */
    text-align: center; /* Center text */
}

.footer-content {
    display: flex; /* Flexbox for social media and links */
    justify-content: space-between; /* Space between items */
    align-items: center; /* Center items vertically */
    max-width: 1200px; /* Max width for larger screens */
    margin: 0 auto; /* Center the footer */
    padding: 0 1rem; /* Padding for smaller screens */
}

.logo-container {
    text-align: center; /* Center the logo */
}

.logo {
    font-size: 2rem; /* Font size for the logo */
    font-weight: bold; /* Make the logo bold */
    color: #ffca28; /* Golden color for the logo */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add some shadow for depth */
}

.golden-line {
    border: none; /* Remove default border */
    height: 2px; /* Set height */
    background-color: #ffca28; /* Golden color */
    margin: 1rem 0; /* Spacing above and below the line */
}

.social-media a {
    margin: 0 10px; /* Add space between icons */
    color: #1dcf34; /* Color for the icons */
    font-size: 1.5rem; /* Icon size */
    transition: color 0.2s ease; /* Transition for hover effect */
}

.social-media a:hover {
    color: #ffca28; /* Change color on hover */
}

.footer-links {
    margin-top: 1rem; /* Margin for footer links */
}

.footer-links a {
    color: #1dcf34; /* Link color */
    margin: 0 10px; /* Margin between links */
    text-decoration: none; /* No underline */
    transition: color 0.3s ease; /* Transition for hover effect */
}

.footer-links a:hover {
    color: #ffca28; /* Change color on hover */
}

.footer-bottom {
    margin-top: 1rem; /* Margin above copyright text */
    font-size: 0.9rem; /* Smaller font size */
}

/* Mobile responsive styles */
@media (max-width: 768px) {
    .footer-content {
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center items */
    }

    .footer-links {
        margin-top: 1rem; /* Margin for footer links */
    }
}
