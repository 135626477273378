/* General styling for the gallery container */
.gallery-section {
  padding: 20px; /* Add padding to the section */
  padding-top: 80px; /* Add more padding on top for heading visibility below the navbar */
}

.gallery-section h2 {
  text-align: center; /* Center the heading */
  margin-bottom: 20px; /* Space below the heading */
  color: white; /* Change heading color to white */
  position: relative; /* Position for the pseudo-element */
  font-size: 2em; /* Increase font size for emphasis */
}

/* Underline effect for the heading */
.gallery-section h2::after {
  content: ""; /* Empty content for the pseudo-element */
  display: block; /* Makes it behave like a block element */
  width: 50%; /* Width of the underline */
  height: 4px; /* Thickness of the underline */
  background: #FF5F6D; /* Underline color */
  margin: 10px auto; /* Center the underline */
  transition: width 0.4s ease, background 0.4s ease; /* Smooth transition for width and color */
}

/* Hover effect for the heading underline */
.gallery-section h2:hover::after {
  width: 100%; /* Full width on hover */
  background: #FFC371; /* Change color on hover */
}

/* Responsive grid for gallery */
.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive grid */
  gap: 20px; /* Spacing between images */
  padding: 20px;
  justify-items: center; /* Center each image in its grid cell */
  max-width: 1200px; /* Limit the width for large screens */
  margin: 0 auto; /* Center the gallery horizontally */
}

/* Individual gallery images */
.gallery img {
  width: 100%;
  height: 250px; /* Fixed height for the images */
  object-fit: cover; /* Ensures images cover the area without distortion */
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effects */
}

/* Hover effect on images */
.gallery img:hover {
  transform: scale(1.05); /* Slight zoom effect */
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}

/* For smaller screens like mobile */
@media (max-width: 768px) {
  .gallery-section {
    padding-top: 100px; /* Ensure the heading is visible under the navbar */
  }

  .gallery {
    grid-template-columns: 1fr; /* Single column layout on mobile */
    gap: 10px; /* Reduced gap for smaller screens */
  }

  .gallery img {
    height: auto; /* Maintain aspect ratio on mobile */
  }
}
