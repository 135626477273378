/* General styles */
.home-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  gap: 10px;
  padding: 20px;
  height: 100vh; /* Full viewport height */
  align-items: center;

}

.image-section img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
  z-index: 1; /* Ensure the image is above other elements */
}

.content-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
}

.content-section h1 {
  color: #940b0b;
  font-size: 2.5rem;
  margin-bottom: 20px;
}
.content-section h3 {
  color: white;
  font-size: 1.5rem;
  margin-bottom: 10px;
  text-decoration: underline; /* Underline the title */
  text-decoration-color: var(--AccentColor); /* Golden yellow underline */
}

p {
  color: white;
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.explore-btn {
  padding: 10px 20px;
  background-color: var(--AccentColor);
  color: var(--textColor);
  border: none;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.explore-btn:hover {
  background-color: var(--PrimaryColor);
  color: var(--whiteColor);
}

/* Media query for responsive layout */
@media (max-width: 768px) {
  .home-container {
    grid-template-columns: 1fr; /* Stack items vertically */
    grid-template-rows: auto auto; /* Two rows, one for image, one for content */
    gap: 10px;
    padding: 10px;
    height: auto; /* Adjust height for mobile */
  }

  .image-section {
    order: 1; /* Show the image section first */
    display: flex;
    justify-content: center; /* Center the image horizontally */
    margin-top: 60px; /* Add margin to prevent overlap with navbar */
    padding-top: 20px; /* Additional padding to separate from navbar */
    z-index: 1;
  }

  .image-section img {
    max-width: 80%; /* Adjust image size for mobile */
    height: auto;
  }

  .content-section {
    order: 2; /* Show the content section second */
    padding: 20px;
  }

  h1 {
    font-size: 2rem; /* Reduce font size for mobile */
    margin-bottom: 15px;
  }

  p {
    font-size: 1rem; /* Reduce paragraph size */
    margin-bottom: 20px;
  }

  .explore-btn {
    padding: 8px 16px;
    font-size: 0.9rem; /* Adjust button size for mobile */
  }
}

/* Additional styles for navbar */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999; /* Ensure navbar stays on top of content */
  background-color: var(--PrimaryColor);
  padding: 15px;
}

.home-container {
  padding-top: 80px; /* Ensure there's space for the fixed navbar */
}
