@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  transition: .3s ease;
}

/* Colour Variables */
:root {
  --PrimaryColor: #269ccc;      /* Updated Deep Sky Blue */
  --SecondaryColor: #ff5722;    /* Vibrant Orange */
  --AccentColor: #ffca28;       /* Golden Yellow */
  --HoverColor: #9ed2c5;        /* Updated Lighter Greenish Blue for Hover */
  --LightAccent: #ffecb3;       /* Soft Yellow */
  
  --whiteColor: hsl(0, 0%, 100%);       /* Pure White */
  --blackColor: #7b7b7b;                /* Updated Dark Grey */
  --textColor: hsl(240, 16%, 17%);      /* Updated Dark Navy Blue for Text */
  
  --greyBg: hsl(0, 0%, 96%);            /* Updated Light Grey for Backgrounds */
  --whiteColorDeam: hsl(0, 0%, 93%);    /* Updated Slightly Off-White */
  --greyText: rgb(190, 190, 190);       /* Updated Light Grey for Subtext */
  --inputColor: hsl(330, 12%, 97%);     /* Very Light Blue for Input Backgrounds */
  
  --SuccessColor: #4caf50;              /* Bright Green for Success */
  --WarningColor: #ff9800;              /* Bright Orange for Warnings */
  --ErrorColor: #f44336;                /* Bright Red for Errors */
  --InfoColor: #03a9f4;                 /* Light Blue for Info */
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

.section {
  padding: 4rem 0 2rem;
}

.container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.icon {
  font-size: 2rem;
  cursor: pointer;
}

.flex {
  display: flex;
  align-items: center;
}

.grid {
  display: flex;
  align-items: center;
}

.btn {
  padding: .4rem 1.5rem;
  background: var(--blackColor);
  border: none;
  outline: none;
  border-radius: 3rem;
  cursor: pointer;
}

.btn a {
  color: var(--whiteColor);
  font-weight: 500;
}

.btn:hover a {
  color: var(--AccentColor);
}

.secIntro {
  max-width: 70%;
  text-align: center;
  margin: 0 auto 2rem;
}

.secIntro .secTitle {
  color: var(--blackColor);
  line-height: 1.5rem;
  padding-bottom: .5rem;
}

.secIntro p {
  font-size: 13px;
  color: var(--textColor);
  font-weight: 500;
}

img, video {
  width: 100%;
  height: auto;
}

input {
  font-size: 100%;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {

  .section {
    padding: 2rem 0 1rem; /* Adjusted padding for mobile */
  }

  .container {
    padding-left: 1rem;
    padding-right: 1rem; /* Reduce padding for mobile */
  }

  .icon {
    font-size: 1.5rem; /* Smaller icon size */
  }

  .btn {
    padding: .3rem 1rem; /* Smaller button padding */
  }

  .btn a {
    font-size: 0.9rem; /* Adjust button text size */
  }

  .secIntro {
    max-width: 90%; /* Make the section intro wider on mobile */
  }

  .secIntro p {
    font-size: 12px; /* Smaller font for mobile */
  }

  .flex {
    flex-direction: column; /* Stack flex items vertically */
  }

  .grid {
    flex-direction: column; /* Stack grid items vertically */
  }

  img, video {
    width: 100%;
    height: auto; /* Ensure media is responsive */
  }

  input {
    font-size: 90%; /* Adjust input text size for mobile */
  }
}
