/* General styling for the service section */
:root {
  --PrimaryColor: #269ccc;      /* Deep Sky Blue */
  --SecondaryColor: #ff5722;    /* Vibrant Orange */
  --AccentColor: #ffca28;       /* Golden Yellow */
  --HoverColor: #9ed2c5;        /* Lighter Greenish Blue */
  --LightAccent: #ffecb3;       /* Soft Yellow */
  
  --whiteColor: hsl(0, 0%, 100%);       /* Pure White */
  --blackColor: #7b7b7b;                /* Dark Grey */
  --textColor: hsl(240, 16%, 17%);      /* Dark Navy Blue */
  
  --greyBg: hsl(0, 0%, 96%);            /* Light Grey */
  --whiteColorDeam: hsl(0, 0%, 93%);    /* Slightly Off-White */
  --greyText: rgb(190, 190, 190);       /* Light Grey */
  --inputColor: hsl(330, 12%, 97%);     /* Light Blue for Input Backgrounds */
  
  --SuccessColor: #4caf50;              /* Bright Green */
  --WarningColor: #ff9800;              /* Bright Orange */
  --ErrorColor: #f44336;                /* Bright Red */
  --InfoColor: #03a9f4;                 /* Light Blue */
}

/* Service Section */
.service-section {
  padding: 50px 0;
  background-color: var(--whiteColor);
}

/* Container for service section */
.service-sec-container {
  text-align: center;
  padding: 30px;
  border-radius: 30px; /* Apply rounded corners fully */
  background-color: var(--whiteColor); /* Ensure background is applied */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.411); /* Adjusted shadow for more depth */
  overflow: hidden; /* Ensure no content overflows the container */
}
/* Service Section Title */
.service-sec-title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #027c68; /* Primary color for title */
  text-decoration: underline; /* Underline the title */
  text-decoration-color: var(--AccentColor); /* Golden yellow underline */
  border-radius: 10px; /* Apply rounded borders to the title */
}

/* Paragraph within the service section */
.service-sec-container p {
  font-size: 18px;
  margin-bottom: 10px;
  color: var(--InfoColor); /* Navy blue text */
}

/* Main Container */
.service-main-container {
  position: relative;
  display: flex;
  justify-content: center;
}

/* Swiper Container */
.service-swiper {
  background:  #5dade2 ;
  padding: 80px;
  width: 90%;
  border-radius: 20px;
  margin: 0 auto;
}

/* Individual Swiper Slide */
.service-swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 15px; /* Rounded corners */
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for depth */
}

/* Content within Swiper Slides */
.service-slide-content {
  position: absolute;
  bottom: 15px;
  left: 15px;
  background: rgba(243, 243, 243, 0.795); /* Slightly darker background */
  padding: 10px;
  border-radius: 5px; /* Rounded content */
  text-align: left;
}

.service-slide-content h2 {
  font-size: 15px;
  margin-bottom: 10px;
  color: var(--SecondaryColor); /* Orange for title */
  text-decoration: underline;
  text-decoration-color: var(--AccentColor); /* Golden yellow underline */
}

/* Service Slider Button */
.service-slider-btn {
  display: inline-block;
  padding: 3px 3px;
  background-color: var(--AccentColor); /* Primary color for button */
  color: var(--whiteColor);
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.service-slider-btn:hover {
  background-color: var(--HoverColor); /* Hover with lighter greenish-blue */
}

/* Pagination Styling */
.swiper-pagination-bullet {
  background: var(--PrimaryColor); /* Deep sky blue for bullets */
  opacity: 1;
  transition: background 0.3s;
}

.swiper-pagination-bullet-active {
  background: var(--SecondaryColor); /* Orange for active bullet */
}

/* Tablet Adjustments */
@media (max-width: 768px) {
  .service-sec-title {
    font-size: 28px;
  }

  .service-sec-container p {
    font-size: 16px;
  }
  .service-swiper {
    padding: 30px; /* Reduce padding for tablet devices */
    border-radius: 15px; /* Adjust border-radius for tablet */
  }

  .service-swiper-slide {
    height: 300px;
  }

  .service-slide-content h2 {
    font-size: 10px;
  }

  .service-slide-content p {
    font-size: 14px;
  }

}

/* Mobile Adjustments */
@media (max-width: 480px) {
  .service-sec-title {
    font-size: 24px;
  }

  .service-sec-container p {
    font-size: 14px;
  }
  .service-swiper {
    padding: 20px; /* Further reduce padding for mobile devices */
    border-radius: 10px; /* Adjust border-radius for mobile */
    /* Optional: Adjust width if needed */
    width: 95%; /* Ensure it takes up most of the screen */
  }

  .service-swiper-slide {
    height: 250px;
  }

  .service-slide-content h2 {
    font-size: 18px;
  }

  .service-slide-content p {
    font-size: 12px;
  }
}
